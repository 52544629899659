import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/independence_day/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/independence_day/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/independence_day/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/independence_day/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/independence_day/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/2023/independence_day/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/2023/independence_day/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/2023/independence_day/8.jpg';
import p9 from 'assests/Photos/SchoolProgram/2023/independence_day/9.jpg';
import p10 from 'assests/Photos/SchoolProgram/2023/independence_day/10.jpg';
import p11 from 'assests/Photos/SchoolProgram/2023/independence_day/11.jpg';
import p12 from 'assests/Photos/SchoolProgram/2023/independence_day/12.jpg';
import p13 from 'assests/Photos/SchoolProgram/2023/independence_day/13.jpg';
import p14 from 'assests/Photos/SchoolProgram/2023/independence_day/14.jpg';
import p15 from 'assests/Photos/SchoolProgram/2023/independence_day/15.jpg';
import p16 from 'assests/Photos/SchoolProgram/2023/independence_day/16.jpg';
import p17 from 'assests/Photos/SchoolProgram/2023/independence_day/17.jpg';
import p18 from 'assests/Photos/SchoolProgram/2023/independence_day/18.jpg';
import p19 from 'assests/Photos/SchoolProgram/2023/independence_day/19.jpg';
import p20 from 'assests/Photos/SchoolProgram/2023/independence_day/20.jpg';
import p21 from 'assests/Photos/SchoolProgram/2023/independence_day/21.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarArticles } from 'views/SchoolProgram/components';
import Archive2022 from './components/Archive2022';
import { Sidebar2023 } from './components';

const SchoolProgram2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 2,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
       
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 2,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        
       
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 2,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
       
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },       
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 2,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
       
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 2,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
            src: p19,
            source: p19,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    INDEPENDENCE DAY
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary  Date: 17 AUGUST 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Pre-Primary department eagerly participated in the 77th Independence Day celebration of India on Thursday, 17 August 2023 with great enthusiasm and patriotic fervor. The celebration aimed at introducing young minds to the significance of this historic day and instilling a sense of pride and love for the country.
                                    <br></br>
                                    The students were dressed in Tri-colour attire for this occasion and enjoyed the cultural festivities performed by their peers which included a patriotic song, an insightful skit and an electrifying dance that mesmerized the audience thoroughly.
                                    <br></br>
                                    The song “Vande Mataram” sung by the young choir group set the rhythm for the event with their melodious rendition. The theme of the skit was about empathy, reaching out to others and to lend a helping hand. The students depicted the essence of these qualities meaningfully through their thoughtful actions. The event culminated with a power packed group dance on a patriotic song which was performed by the little ones with great spirits and set the stage ablaze with their incredible performance. The students enjoyed the whole event and took valuable lessons about respecting our country and appreciating freedom with responsibility. 
                                    <br></br>
                                    The students further participated in classroom discussions and art activities to understand the significance of this historic National Festival. It was a day well celebrated and will be remembered for a long time.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “HAPPINESS DEPENDS ON BEING FREE AND FREEDOM DEPENDS ON BEING COURAGEOUS”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default SchoolProgram2023;